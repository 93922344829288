import React, { useContext } from "react";

import CountryDropdown from "./NeighborhoodDropdown";
import PropertyDropdown from "./PropertyDropdown";
import PriceRangeDropdown from "./PriceRangeDropdown";
import LawServicesDropdown from "./LawServicesDropdown";

import { RiSearch2Line } from "react-icons/ri";
import { HouseContext } from "./HouseContext";

const Search = () => {
  const { handleClick } = useContext(HouseContext);
  return (
    <div
      className="px-[30px] py-6 max-w-[1170px] mx-auto flex flex-col lg:flex-row justify-between gap-4
    lg:gap-x-3 relative lg:-top-14 lg:shadow-1 bg-white lg:bg-transparent lg:backdrop-blur rounded-lg"
    >
      <LawServicesDropdown />
      <CountryDropdown />
      <PropertyDropdown />
      <PriceRangeDropdown />
      <button
        onClick={() => handleClick()}
        className="bg-blue-800 hover:bg-blue-900 transition w-full lg:max-w-[162px] h-16 
      rounded-lg flex justify-center items-center text-white text-lg"
      >
        <RiSearch2Line />
      </button>
    </div>
  );
};

export default Search;
