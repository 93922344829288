import React from "react";

import { Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import PropertyDetails from "./pages/PropertyDetails";
import NotFound from "./components/NotFound";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import ManageProperties from "./pages/ManageProperties";

import PrivateRoute from "./PrivateRoute"

const App = () => {
  return (
    
    <div className="relative bg-main">
      <div className="hidden xl:block absolute left-0 h-full w-16 bg-gradient-to-b from-yellow-500 to-yellow-600 z-[1]"></div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/property/:id" element={<PropertyDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<PrivateRoute> <Admin /></PrivateRoute>} />
        <Route path="/admin/newproperty" element={<PrivateRoute> <ManageProperties /></PrivateRoute>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
