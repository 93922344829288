import React, { useContext } from "react";

import { HouseContext } from "./HouseContext";
import { ImSpinner2 } from "react-icons/im";

import { Link } from "react-router-dom";

import House from "./House";

const HouseList = () => {
  const { allProperties, loading } = useContext(HouseContext);

  if (loading) {
    return (
      <ImSpinner2 className="mx-auto animate-spin text-blue-800 text-4xl mt-[200px]" />
    );
  }
  if (allProperties.length < 1) {
    return (
      <div className="text-center text-3xl text-gray-400 mt-48">
        Lamento, não consegui encontrar nenhuma propriedade.
      </div>
    );
  }

  return (
    <section className="sm:mt-0 mt-36 mb-20">
      <div className="container mx-auto">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-14">
          {allProperties.map((house, index) => {
            return (
              <Link to={`/property/${house.id}`} key={index}>
                <House house={house} />
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default HouseList;
