import { useState } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { GoLaw } from "react-icons/go";
import { Menu } from "@headlessui/react";

const SubMenuItem = ({ subItem }) => {
  return (
    <Menu.Item as="div">
      <div className="mt-4 text-sm text-blue-800">{subItem.value}</div>
    </Menu.Item>
  );
};

const MenuItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSubItems = item.subItems && item.subItems.length > 0;

  const handleItemClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Menu.Item as="div">
      <div
        className={`flex items-center text-sm ${
          hasSubItems ? "cursor-pointer" : ""
        }`}
        onClick={handleItemClick}
      >
        {item.value}
        {hasSubItems && (
          <RiArrowDownSLine
            className={`text-xs ml-1 ${isOpen && "transform rotate-180"}`}
          />
        )}
      </div>
      {hasSubItems && isOpen && (
        <Menu.Items static className="pl-4">
          {item.subItems.map((subItem) => (
            <SubMenuItem key={subItem.value} subItem={subItem} />
          ))}
        </Menu.Items>
      )}
    </Menu.Item>
  );
};

const MenuButton = ({ onClick, isOpen }) => (
  <Menu.Button
    onClick={onClick}
    className="bg-white dropdown-btn w-full text-left mr-10"
  >
    <GoLaw className="dropdown-icon-primary" />
    <div>
      <div className="text-[15px] font-medium leading-tight">
        Serviços Jurídicos
      </div>
      <div className="text-[10px]">Nossos parceiros</div>
    </div>
    {isOpen ? (
      <RiArrowUpSLine className="dropdown-icon-secondary" />
    ) : (
      <RiArrowDownSLine className="dropdown-icon-secondary" />
    )}
  </Menu.Button>
);

const LawServicesDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const services = [
    {
      value: "Direito Previdenciário",
      subItems: [
        { value: "• Aposentadoria por idade" },
        { value: "• Aposentadoria por tempo de contribuição" },
        { value: "• Auxílio-doença" },
      ],
    },
    {
      value: "Direito Penal",
      subItems: [
        { value: "• Crimes contra o patrimônio" },
        { value: "• Crimes contra a pessoa" },
        { value: "• Crimes contra a honra" },
      ],
    },
    {
      value: "Direito Trabalhista",
      subItems: [
        { value: "• Rescisão de contrato" },
        { value: "• Assédio no ambiente de trabalho" },
        { value: "• Férias e 13º salário" },
      ],
    },
    {
      value: "Direito Cível",
      subItems: [
        { value: "• Direito Imobiliário" },
        { value: "• Responsabilidade civil" },
        { value: "• Direito das famílias" },
      ],
    },
  ];

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleNameClick = (name, event) => {
    event.stopPropagation();
    if (name === "Márcio Menezes") {
      window.open("https://wa.link/lhp4c4", "_blank");
    } else if (name === "Giovanna Fischer") {
      window.open("https://wa.link/p64jfw", "_blank");
    }
  };

  return (
    <Menu as="div" className="dropdown relative">
      <MenuButton onClick={handleMenuClick} isOpen={isOpen} />
      {isOpen && (
        <Menu.Items static className="dropdown-menu max-w-xs">
          <div>
            <div
              className="text-[15px] font-medium hover:font-bold leading-tight"
              onClick={(event) => handleNameClick("Giovanna Fischer", event)}
            >
              Giovanna Fischer
              <div className="text-[10px]">OAB/PE: 53.716</div>
            </div>
            <div
              className="text-[15px] font-medium hover:font-bold leading-tight mt-3"
              onClick={(event) => handleNameClick("Márcio Menezes", event)}
            >
              Márcio Menezes
              <div className="text-[10px]">OAB/PE: 29.976</div>
            </div>
          </div>
          {services.map((service, index) => (
            <MenuItem key={index} item={service} />
          ))}
        </Menu.Items>
      )}
    </Menu>
  );
};

export default LawServicesDropdown;
